import "./IndexPage.css";
import {useUserContext} from "../../../context/userContext/context";
import {timeSince} from '../../../utils/utils'
import {Link, NavLink} from "react-router-dom";

const IndexPage = () => {
    const {user} = useUserContext()
    return <div>
        <h1>Welcome to Psalm-ext.xyz</h1>

    </div>;
};
export default IndexPage;
