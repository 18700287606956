import "./Modal.css";
import {useEffect, useState} from "react";

const Modal = ({children, className, buttonHtml,visible}) => {
    let [vis, setVisible] = useState(visible||false)

    useEffect(() => {
        setVisible(visible)
    }, [visible]);
    return <>


        <span onClick={() => setVisible(!vis)}>{buttonHtml ||  <div className={'socBoxBtn '}

                                   style={{width: 'fit-content'}}>"Display Modal"     </div>}</span>

        {vis && <div className={`modalBody ${className}`}>
            <div className={'closeModal socBoxBtn'} onClick={() => setVisible(false)}> Close</div>
            {children}
        </div>}
    </>
};
export default Modal;
