import "./AgendashPage.css";
import {useUserContext} from "../../../context/userContext/context";
import {Link, NavLink} from "react-router-dom";
import {useState} from "react";

const AgendashPage = () => {
    const {user} = useUserContext()
    return <div>
       <iframe
            src={'https://psalm.blogsportivo.xyz/dash'}
            style={{width: '99vw', height: '90vh'}}/>
    </div>;
};
export default AgendashPage;
