import "./NavBar.css";
import {NavLink} from "react-router-dom";
import {useUserContext} from "../../../context/userContext/context";
import {useEffect} from "react";
import Loader from "../loader/Loader";

const NavBar = () => {

    const {user, userLoading, getUserData, logoutUser} = useUserContext()
    useEffect(() => {
        getUserData()
    }, [])
    console.log(user)
    if (userLoading) return <nav><Loader/></nav>
    if (!user || user.needAuth) return <nav>
        <NavLink to={'/login'}
                 className={({isActive, isPending}) =>
                     isPending ? "navLink" : isActive ? "navLink active" : "navLink"
                 }>
            Login
        </NavLink>
    </nav>

    return <nav>





        <NavLink
            to={'/dashboard'}
            className={({isActive, isPending}) =>
                isPending ? "navLink" : isActive ? "navLink active" : "navLink"
            }>
            User Dashboard
        </NavLink>

        <NavLink
            to={'/linksPanel'}
            className={({isActive, isPending}) =>
                isPending ? "navLink" : isActive ? "navLink active" : "navLink"
            }>
            Links Panel
        </NavLink>
        <NavLink
            to={'/manualPanel'}
            className={({isActive, isPending}) =>
                isPending ? "navLink" : isActive ? "navLink active" : "navLink"
            }>
            Manual Panel
        </NavLink>
        <NavLink
            to={'/createTasksPanel'}
            className={({isActive, isPending}) =>
                isPending ? "navLink" : isActive ? "navLink active" : "navLink"
            }>
            Create Task
        </NavLink>
        <NavLink
            to={'/reviewsPanel'}
            className={({isActive, isPending}) =>
                isPending ? "navLink" : isActive ? "navLink active" : "navLink"
            }>
           Reviews
        </NavLink>
        <NavLink
            to={'/login'}> <div className={'navLink'} onClick={logoutUser}>Logout</div>
        </NavLink>
    </nav>;
};
export default NavBar;
