import "./LinksPanelPage.css";
import {useUserContext} from "../../../context/userContext/context";
import {Link, NavLink} from "react-router-dom";
import {useState} from "react";

const LinksPanelPage = () => {
    const {user} = useUserContext()
    return <div>
 <iframe
            src={'https://psalm.blogsportivo.xyz/static/linksPanel.html?token=27b3c0f2f3954ff5a10477ec50e44f22&accessToken=t0v435up3R1353NT0k3N123'}
            style={{width: '99vw', height: '90vh'}}/>
    </div>;
};
export default LinksPanelPage;
