import "./ControlPanelPage.css";



const ControlPanelPage = () => {


    return <div>

    </div>;
};
export default ControlPanelPage;
