import React, {useReducer, useContext, createContext} from 'react';

import axios from '../../config/axiosConfig';


import reducer from './reducer';

import {
    LOGOUT_USER, LOGOUT_USER_BEGIN,
    LOGOUT_USER_ERROR,
    SETUP_USER_ERROR,
    STORE_USER_DATA,
} from './actions.js';


const initialState = {
    user: null,
    userLoading: true,
    users: []
}
const UserContext = createContext();

const UserProvider = ({children}) => {

    const [state, dispatch] = useReducer(reducer, initialState);


    const getUserData = async () => {

        try {
            const response = await axios('/getLocals');
            const {data} = response;
            const user = data;

            dispatch({
                type: STORE_USER_DATA,
                payload: {
                    user,
                }
            });

        } catch (error) {
            console.log(error);
            dispatch({
                type: SETUP_USER_ERROR,
                payload: {
                    msg: error
                }

            })
        }
    }

    const logoutUser = async ()=> {

        try {
            dispatch({
                type: LOGOUT_USER_BEGIN,
                payload: {
                }
            });
            const response = await axios('/logoutPanelUser');

            dispatch({
                type: LOGOUT_USER,
                payload: {
                    user:null,
                }
            });

        } catch (error) {
            console.log(error);
            dispatch({
                type: LOGOUT_USER_ERROR,
                payload: {
                    msg: error
                }

            })
        }
    }


    return <UserContext.Provider value={{
        ...state,
        getUserData,logoutUser

    }}>
        {children}
    </UserContext.Provider>
}

const useUserContext = () => {
    return useContext(UserContext);
}

export {UserProvider, initialState, useUserContext}