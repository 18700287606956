import React, {useReducer, useContext, createContext} from 'react';

import reducer from './reducer';

import {
    INITIAL_LOAD_BEGIN,
    INITIAL_LOAD_SUCCESS,
    INITIAL_LOAD_ERROR, STORE_PANEL_DATA,
} from './actions';
import axios from "../../config/axiosConfig";


const initialState = {
    psalmLoading: true,
    errorMessage: '',
    personNames: [],
    reviewsList: []
}

const PsalmContext = createContext();

const PsalmProvider = ({children}) => {

    const [state, dispatch] = useReducer(reducer, initialState);

    const loadInitialData = () => {
        try {
            dispatch({type: INITIAL_LOAD_BEGIN});

            //todo fetch


            //todo dispatch success

        } catch (e) {
            dispatch({
                type: INITIAL_LOAD_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }
    const getReviewPanelData = async (brandName) => {
        try {
            dispatch({type: INITIAL_LOAD_BEGIN});

            let [names, reviews] = await Promise.all([axios.get(`/getPersonalNames`), axios.get(`/getProductReviews?brandName=${brandName}`)])


            dispatch({type: STORE_PANEL_DATA, payload: {personNames: names.data, reviewsList: reviews.data}})
        } catch (e) {
            dispatch({
                type: INITIAL_LOAD_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }

    const submitPersonalNames = async (namesList, lang) => {
        try {
            dispatch({type: INITIAL_LOAD_BEGIN});

            let resp = await axios.post('/submitPersonNames', {namesList, lang})

            dispatch({
                type: STORE_PANEL_DATA,
                payload: {personNames: [...resp.data, ...state.personNames.filter(a => a.lang !== lang)]}
            })
        } catch (e) {
            dispatch({
                type: INITIAL_LOAD_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }

    }
    const deletePersonalName = async (nameId) => {
        try {
            dispatch({type: INITIAL_LOAD_BEGIN});

            let resp = await axios.post('/deletePersonalName', {nameId})

            dispatch({
                type: STORE_PANEL_DATA,
                payload: {personNames: [...state.personNames.filter(a => a._id !== nameId)]}
            })
        } catch (e) {
            dispatch({
                type: INITIAL_LOAD_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }

    const updateProductReviews = async (query, update) => {
        try {
            dispatch({type: INITIAL_LOAD_BEGIN});

            let resp = await axios.post('/updateProductReviews', {query, update})

            dispatch({
                type: STORE_PANEL_DATA,
                payload: {reviewsList: [resp.data, ...state.reviewsList.filter(a => a._id !== query._id)]}
            })
        } catch (e) {
            dispatch({
                type: INITIAL_LOAD_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }

    }
    const bulkUpdateProductReviews = async (query, update) => {
        try {
            dispatch({type: INITIAL_LOAD_BEGIN});

            let resp = await axios.post('/bulkUpdateProductReviews', {query, update})
            console.log(resp.data, query._id, state.reviewsList.filter(a => query._id.$in.includes(a._id)))
            dispatch({
                type: STORE_PANEL_DATA,
                payload: {reviewsList: [...resp.data, ...state.reviewsList.filter(a => !query._id.$in.includes(a._id))]}
            })
        } catch (e) {
            dispatch({
                type: INITIAL_LOAD_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }

    }

    const deleteProductReviews = async (reviewId) => {
        try {
            dispatch({type: INITIAL_LOAD_BEGIN});

            let resp = await axios.post('/deleteProductReviews', {reviewId})

            dispatch({
                type: STORE_PANEL_DATA,
                payload: {reviewsList: [...state.reviewsList.filter(a => a._id !== reviewId)]}
            })
        } catch (e) {
            dispatch({
                type: INITIAL_LOAD_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }

    return <PsalmContext.Provider value={{
        ...state,
        loadInitialData,
        getReviewPanelData,
        submitPersonalNames,
        deletePersonalName,
        updateProductReviews,
        deleteProductReviews,
        bulkUpdateProductReviews
    }}>
        {children}
    </PsalmContext.Provider>
}


const usePsalmContext = () => {
    return useContext(PsalmContext);
}

export {PsalmProvider, initialState, usePsalmContext}