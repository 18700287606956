import {useState} from "react";
import {removeDuplicates} from "../../../utils/utils";
import {usePsalmContext} from "../../../context/psalmContext/context";
import Modal from "../../partials/modal/Modal";
import {timeSince} from "../../../utils/utils";

const ProductCard = ({
                         _id,
                         productUrl,
                         weight,
                         isDuplicate,
                         productName,
                         texts,
                         usedTexts,
                         active,
                         setIsSelected,
                         nextReview,
                         selected,
    firstTaskErrorCount,
    secondTaskErrorCount,
                     }) => {
    const {
        updateProductReviews,
        deleteProductReviews
    } = usePsalmContext()
    let [expanded, setExpanded] = useState(false)
    let [addMore, setAddMore] = useState(false)
    let [newTexts, setNewTexts] = useState('')
    let [deletePrompt, setDeletePrompt] = useState(false)
    const [tempWeight, setTempWeight] = useState(weight || 0)
    const addMoreTextsFunc = () => {
        let update = {$addToSet: {texts: {$each: removeDuplicates(newTexts.split('\n').map(a=>a.trim()).filter(a => a))}}}
        updateProductReviews({_id}, update)
        setAddMore(false)
        setNewTexts('')
    }

    const deleteTextFunc = (e) => {
        let update = {$pull: {texts: e.target.getAttribute('data-text')}}
        updateProductReviews({_id}, update)
    }
    const markUnusedFunc = (e) => {
        let update = {$pull: {usedTexts: e.target.getAttribute('data-text')}}
        updateProductReviews({_id}, update)
    }
    const markUsedFunc = (e) => {
        let update = {$addToSet: {usedTexts: e.target.getAttribute('data-text')}}
        updateProductReviews({_id}, update)
    }
    const updateWeightFunc = (e) => {
        let update = {weight: Number(e.target.value)}
        updateProductReviews({_id}, update)
    }
    const toggleActiveFunc = (e) => {
        let update = {active: !active}
        updateProductReviews({_id}, update)
    }

    return <div key={_id} >
        <div
            className={`productTitle ${isDuplicate ? 'duplicate' : ""} ${active ? "activeProd" : "inactiveProd"}`}>
            <input type={"checkbox"} checked={selected} onChange={setIsSelected} style={{width: 20, height: 20, marginRight:20}}/>
            <span onClick={() => {
                if (!expanded) setExpanded(true)
            }}>{productName}</span>
            <span style={{marginLeft: 10}} className={'socBoxBtn'}
                  onClick={() => toggleActiveFunc(true)}>{active ? "⏸" : "▶"}                </span>
            {active && <strong style={{color: "cyan"}}>{" 🥇 once every ~"}
                <input className={'weightInput'}
                       value={tempWeight}
                       onChange={e => setTempWeight(Number(e.target.value))}
                       onBlur={updateWeightFunc}
                       type={'number'}/> <small>days</small></strong>}
            {!expanded && <span
                className={'progressData'}>	 💬 {texts.length} ✓ {usedTexts.length}
                <strong style={{
                    color: "orange",
                    transform: "scale(1.1)",
                    display: "inline-block"
                }}>⌛ {Math.max(0, texts.length - usedTexts.length)}</strong> {active && <>(next:
                    in {timeSince(nextReview)})</>} ⚠ {firstTaskErrorCount} ⚠⚠ {secondTaskErrorCount}</span>}
            <a target={'_blank'} className={`${isDuplicate ? 'duplicate' : ""}`}
               href={productUrl}>{productUrl.substring(0, 50)}{productUrl.length > 50 ? "..." : ""}</a>
            {expanded && <>
                <span style={{marginLeft: 10}} className={'socBoxBtn'}
                      onClick={() => setExpanded(false)}>Collapse</span>
                <span style={{marginLeft: 10}} className={'socBoxBtn'}
                      onClick={() => setAddMore(true)}>Add Texts                </span>

                <Modal visible={deletePrompt}
                       buttonHtml={<span className={'socBoxBtn'}
                                         style={{float: 'right', marginRight: 25, marginTop: -9}}
                                         onClick={() => setDeletePrompt(true)}>Delete Product and Reviews</span>}>
                    <h2>Are you sure you want to delete {productName}</h2>
                    <strong>This will also remove all texts and data about written reviews on the product</strong>

                    <div style={{display: "flex", gap: 40, padding: 30}}>
                        <div className={'socBoxBtn'} onClick={() => {
                            deleteProductReviews(_id)
                            setDeletePrompt(false)
                        }}> YES
                        </div>
                        <div className={'socBoxBtn'} onClick={() => setDeletePrompt(false)}> NO</div>
                    </div>
                </Modal>
            </>}
        </div>

        {expanded && <>
            {addMore && <>
                <textarea className={'socBoxTextarea fullWidth'} rows={15} value={newTexts}
                          style={{overflowX: "scroll", overflowWrap: 'normal', whiteSpace: "pre"}}
                          placeholder={'each review on a new line'}
                          onChange={(e) => setNewTexts(e.target.value.replaceAll('"', "").replaceAll("'", ""))}></textarea>

                <div className={`socBoxBtn`} onClick={addMoreTextsFunc}>Add texts</div>
            </>}
            <ul>
                {texts.map(a => {
                    let isUsed = usedTexts.includes(a)
                    return (<li key={a} className={isUsed ? "used" : "not-used"}>{a}
                        {!isUsed && <span className={'textAction'} data-text={a} onDoubleClick={markUsedFunc}>🚽</span>}

                        <span className={'textAction'} data-text={a} onDoubleClick={deleteTextFunc}>❌</span>
                    </li>)
                })}
            </ul>

        </>}
    </div>
};
export default ProductCard;
