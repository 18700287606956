import {
    INITIAL_LOAD_BEGIN,
    INITIAL_LOAD_SUCCESS,
    INITIAL_LOAD_ERROR,
    STORE_PANEL_DATA

} from './actions';

const reducer = (state, action) => {

    if (action.type === INITIAL_LOAD_BEGIN) {
        return {
            ...state,
            psalmLoading: true
        }
    }

    if ([INITIAL_LOAD_SUCCESS, STORE_PANEL_DATA].includes(action.type)) {

        return {
            ...state,
            psalmLoading: false,
            ...action.payload
        }
    }

    if (action.type === INITIAL_LOAD_ERROR) {
        return {
            ...state,
            psalmLoading: false,
            errorMessage: action.payload.msg
        }
    }


    throw new Error(`no such action : ${action.type}`);
}

export default reducer;