import "./CreateTasksPanelPage.css";
import {useUserContext} from "../../../context/userContext/context";
import {useState} from "react";

const CreateTasksPanelPage = () => {
    const {user} = useUserContext()
    return <div>
        <iframe
            src={'https://psalm.blogsportivo.xyz/static/createTask.html?token=27b3c0f2f3954ff5a10477ec50e44f22&accessToken=t0v435up3R1353NT0k3N123'}
            style={{width: '99vw', height: '90vh'}}/>
    </div>;
};
export default CreateTasksPanelPage;
